import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'reviewText', // text field with review
    'radioBtn', // review star button
    'openReviewsModalBtn', // button to open a modal with feedback
    'submitBtnInModal', // button to submit form data
    'leaveFeedbackModal', // modal window with feedback
    'reviewOnServicesModal' // modal window with services where you can leave a review
  ];

  currentReviewNumber = 0;

  connect () {
    this.updateReviewCard();
  }

  updateReviewCard() {
    const radioBtn = this.radioBtnTargets.find(radioBtn => radioBtn.checked);
    if (!radioBtn) return;

    this.currentReviewNumber = +radioBtn.dataset.text;

    this.openReviewsModalBtnTarget.disabled = false;
  }

  handleReviewText(e) {
    if (e.target.value) {
      this.submitBtnInModalTarget.disabled = false;
    } else {
      this.submitBtnInModalTarget.disabled = true;
    }
  }

  showReviewsModal(e) {
    e.preventDefault();

    if (this.hasReviewOnServicesModalTarget && this.currentReviewNumber > 3) {
      this.showReviewOnServicesModal();
    } else {
      this.showLeaveFeedbackModal();
    }
  }

  showReviewOnServicesModal() {
    this.reviewOnServicesModalTarget.classList.remove('hidden');
  }

  hideReviewOnServicesModal(e) {
    e.preventDefault();
    this.reviewOnServicesModalTarget.classList.add('hidden');
  }

  showLeaveFeedbackModal() {
    this.leaveFeedbackModalTarget.classList.remove('hidden');
  }

  hideLeaveFeedbackModal(e) {
    e.preventDefault();
    this.leaveFeedbackModalTarget.classList.add('hidden');
  }
}
