import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'content', // current selected value in select
    'button', // input for collapsing and expanding select
    'input' // inputs with values for selection in the selector
  ];

  hidden = true;
  defaultInput = null;
  selectedCustomPeriod = JSON.parse(sessionStorage.getItem('selectedCustomPeriod'));
  chartGranularity = JSON.parse(sessionStorage.getItem('chartGranularity'));

  connect() {
    this.defaultInput = this.inputTargets.find(input => input.value === '');
    if (this.inputTarget.type === 'radio') {
      return this._processValue(this.inputTargets.find(input => input.checked));
    }
    this._processValue(this.inputTarget);
  }

  disconnect() {
    // After selecting in the dropdown, the controller reconnects, so we save the values in session storage
    if (this.selectedCustomPeriod && this.chartGranularity) {
      sessionStorage.setItem('selectedCustomPeriod', JSON.stringify(this.selectedCustomPeriod));
      sessionStorage.setItem('chartGranularity', JSON.stringify(this.chartGranularity));
    }
  }

  _resetMultiSelect() {
    this.inputTargets.forEach(input => (input.checked = false));
    this.defaultInput.checked = true;
    this._processValue(this.defaultInput);
  }

  _processValue(target) {
    if (target.type === 'radio') {
      this._updateSelectedText(this._getInputLabelValue(target));
      this.buttonTarget.checked = false;
    } else {
      let inputs = this.inputTargets.filter(input => input.checked);
      if (inputs.length > 1) {
        this.defaultInput.checked = false;
        inputs = inputs.filter(input => input !== this.defaultInput);
      }
      if (inputs.length === 0) return this._resetMultiSelect();

      const value =
        inputs.length === 1 ?
          this._getSelectedInputLabelValue() :
          `${inputs.length} selected`;
      this._updateSelectedText(value);
    }
  }

  _updateSelectedText(value) {
    this.contentTarget.innerText = value;
  }

  // Sending data from the dropdown select to the chart
  sendDataToChart() {
    // The id of the selected period matches the one we need
    if (this.contentTarget.id === 'periods') {
      const dateRange = this.contentTarget.innerText;

      // The chart is displayed in one hour increments
      if (dateRange === 'Today' || dateRange === 'Yesterday') {
        this.dispatch('renderChart', { detail: { dateRange, granularity: 'hour' } });
      } 
      // The chart is displayed in one day increments
      else if (dateRange === 'This week' || dateRange === 'This month') {
        this.dispatch('renderChart', { detail: { dateRange, granularity: 'day' } });
      } 
      // If there is a custom period/day
      else if (this.selectedCustomPeriod && this.chartGranularity) {
        const startDate = moment(this.selectedCustomPeriod[0]);
        const endDate = moment(this.selectedCustomPeriod[1]);
        const days = endDate.diff(startDate, 'days');

        // Take a monthly step in the chart
        if (days > 31 && startDate.year() === endDate.year()) {
          this.chartGranularity = 'month';
        } 
        // Take an annual step in the chart
        else if (days > 31 && startDate.year() !== endDate.year()) {
          this.chartGranularity = 'year';
        }

        this.dispatch('renderChart', {
          detail: {
            dateRange: this.selectedCustomPeriod,
            granularity: this.chartGranularity
          }
        });
      }
    }
  }

  _getSelectedInputLabelValue() {
    const input = this.inputTargets.find(checkbox => checkbox.checked);
    return this._getInputLabelValue(input);
  }

  _getInputLabelValue(input) {
    return input.nextElementSibling.innerText;
  }

  hide(event) {
    if (!this.buttonTarget.checked) return (this.hidden = false);

    if (
      !this.hidden &&
      this.element !== event.target &&
      !this.element.contains(event.target)
    ) {
      this.hidden = true;
      this.buttonTarget.checked = false;
    }
  }

  select({ target }) {
    this._processValue(target);
  }

  showCalendar({ target }) {
    if (
      target.nextElementSibling.innerText === 'Custom day' ||
      target.nextElementSibling.innerText === 'Custom period'
    ) {
      this.dispatch('show', { detail: { selectedValue: target.nextElementSibling.innerText } });
    }
  }

  setSelectedDateInSelect({ detail: { monthNumber, fullMonth, year, day } }) {
    if (this.contentTarget.id === 'periods') {
      this.contentTarget.innerText = `${fullMonth} ${day}`;
      this.buttonTarget.value = `${day}-${monthNumber}-${year}`;

      // Generating a date range to send to the cube
      const startDate = moment(`${year}-${monthNumber}-${day}`, 'YYYY-M-D').format('YYYY-MM-DD');
      const endDate = startDate;
      const dateRange = [startDate, endDate];
      this.selectedCustomPeriod = dateRange;
      this.chartGranularity = 'hour';
    }
  }

  setSelectedRangeDateInSelect({ detail: { selectedStartRange, selectedEndRange } }) {
    if (this.contentTarget.id === 'periods') {
      this.contentTarget.innerText = `${selectedStartRange}-${selectedEndRange}`;
      this.buttonTarget.value = `${selectedStartRange}-${selectedEndRange}`;

      // Generating a date range to send to the cube
      const dateRange = this.contentTarget.innerText
        .split('-')
        .map(date => moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));
      this.selectedCustomPeriod = dateRange;
      this.chartGranularity = 'day';
    }
  }
}
