import TableDetailsBaseController from './table_details/table_details_base_controller';

export default class extends TableDetailsBaseController {
  static targets = [
    ...TableDetailsBaseController.targets,
    'selectedItems',
    'totalCharges',
    'totalTaxes',
    'totalCountWithTaxes',
    'paymentBtn'
  ];

  static values = {
    leftToPay: Number,
    selectedItems: Array,
    totalItemsPrice: { type: Number, default: 0 }
  };

  connect() {
    this.addLoadListener(this);
    this.totalAmount = this.hasLeftToPayValue ? this.leftToPayValue : this.totalValue;
    this._processTaxes();
    this._precessCheckoutFee();
    this._processTips();

    this.userLeftToPayAmount = this.totalAmount;
    this.refreshAmount();
  }

  disconnect() {
    this.removeLoadListener(this);
  }

  _processTaxes() {
    const charges = this.selectedItemsValue.reduce((accum, item) => {
      return accum + item.charges;
    }, 0);
    const taxes = this.selectedItemsValue.reduce((accum, item) => {
      return accum + item.taxes;
    }, 0);
    const allTaxes = charges + taxes;

    this.totalChargesTarget.innerHTML = charges.toFixed(2);
    this.totalTaxesTarget.innerHTML = taxes.toFixed(2);
    this.totalAmount += +allTaxes.toFixed(2);
  }

  refreshAmount() {
    this.totalAmount = 0;
    this.totalAmount = this.totalItemsPriceValue;

    // Show total price with taxes
    this._processTaxes();
    this.yourBillTarget.innerText = (+this.totalAmount).toFixed(2);
    this._precessCheckoutFee();
    this.totalCountWithTaxesTarget.innerText = (+this.totalAmount).toFixed(2);

    // Show total price with taxes and tips
    this._processTips();
    this.amountToPayTarget.innerText = (+this.totalAmount).toFixed(2);

    this.userWantsToPay = (this.totalAmount).toFixed(2);

    if (parseFloat(this.totalCountWithTaxesTarget.innerText) <= 0 ) {
      this.paymentBtnTargets.forEach(target => target.disabled = true);
    } else {
      this.paymentBtnTargets.forEach(target => target.disabled = false);
    }
  }

  refreshAmountWithWhatsLeftToPay() {
    if (this.userWantsToPay < this.userLeftToPayAmount) {
      return;
    }
    
    this.removeAllItems();

    this.totalItemsPriceValue = this.hasTurboLeftToPayTarget ?
      this.turboLeftToPayTarget.innerText :
      this.totalValue;
      
    this.refreshAmount(); 
  }

  submit(e) {
    e.preventDefault();

    if (this.totalItemsPriceValue === 0 ) return;

    this.selectedItemsTarget.value = JSON.stringify(this.selectedItemsValue);
    this._fillTipTypeInput();

    this.formTarget.submit();
  }

  add({ detail: { id, price, tax, charge } }) {
    if (this.selectedItemsValue.length === 0) {
      this.totalItemsPriceValue = 0;
    }
    const itemIndex = this.selectedItemsValue.findIndex(item => item.id === id);

    // Add new item object to selected items
    if (itemIndex === -1) {
      this.selectedItemsValue = [
        ...this.selectedItemsValue,
        {
          quantity: 1,
          id,
          taxes: tax,
          charges: charge
        }
      ]
      this.totalItemsPriceValue = this.totalItemsPriceValue + price;
    }

    this.refreshAmount();
  }

  remove({ detail: { id, price, tax, charge } }) {
    const selectedItemIndex = this.selectedItemsValue.findIndex(item => item.id === id);
    const selectedItem = this.selectedItemsValue[selectedItemIndex];
    // Remove item object from selected items
    if (selectedItem && selectedItem.quantity === 0) {
      this.selectedItemsValue = this.selectedItemsValue.filter(item => item.id !== selectedItem.id);
    // Decrement quantity of selected item
    } else {
      this.selectedItemsValue = this.selectedItemsValue.map((item, index) => {
        if (index === selectedItemIndex) {
          return {
            ...item,
            quantity: item.quantity - 1,
            taxes: item.taxes - tax,
            charges: item.charges - charge
          };
        } else {
          return item;
        }
      });
    }

    this.totalItemsPriceValue = this.totalItemsPriceValue - price;

    this.refreshAmount();
  }

  removeAllItems() {
    // Clear selected items
    this.selectedItemsValue = [];

    // Reset total items price
    this.totalItemsPriceValue = 0;

    // Recalculate the amount
    this.refreshAmount();
  }
}
