import TableDetailsBaseController from './table_details/table_details_base_controller';

export default class extends TableDetailsBaseController {
  static targets = [
    ...TableDetailsBaseController.targets,
    'customSplitInput', // Input for entering a custom payment amount
    'tipsModalOpenBtn' // Button to open a modal window with tips
  ];

  static values = { leftToPay: Number };

  // Total amount without checkout fee 
  amountWithoutCheckoutFee = 0;

  connect() {
    this.addLoadListener(this);
    this._assignAmounts();
  }

  disconnect() {
    this.removeLoadListener(this);
  }

  clearCustomSplitInput() {
    this.customSplitInputTarget.value = '';
    this.refreshAmount();
  }

  refreshAmount() {
    this.totalAmount = 0;

    this._addInputValue(this.customSplitInputTarget.value);

    this.yourBillTarget.innerText = (+this.totalAmount).toFixed(2);

    this._precessCheckoutFee();
    this._processTips();

    this.amountToPayTargets.forEach(amountToPayTarget => amountToPayTarget.innerText = (+this.totalAmount).toFixed(2));

    // If the entered amount is greater than the total payment amount, disable the button and mark the input in red
    if (+this.customSplitInputTarget.value > +this.amountWithoutCheckoutFee) {
      this.customSplitInputTarget.parentNode.classList.add('!border-warning-red');
      this.tipsModalOpenBtnTarget.setAttribute('disabled', true);
    } else {
      this.customSplitInputTarget.parentNode.classList.remove('!border-warning-red');
      this.tipsModalOpenBtnTarget.removeAttribute('disabled');
    }
    this.userWantsToPay = this.totalAmount;
  }

  refreshAmountWithWhatsLeftToPay() {
    if (this.userWantsToPay < this.userLeftToPayAmount) {
      return;
    }
    this._assignAmounts();
  }

  _assignAmounts() {
    const leftToPay = this.leftToPayValue;

    this.totalAmount = leftToPay;
    this.customSplitInputTarget.value = leftToPay;
    this.amountWithoutCheckoutFee = leftToPay;

    this.refreshAmount();
    this.userLeftToPayAmount = this.totalAmount;
  }
}
