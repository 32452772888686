import moment from 'moment';

import CubeController from './cube_controller';

export default class extends CubeController {

  static values = { currency: String };

  // A method for formatting strings so that the key and value in custom tooltip have different colors
  _formatTooltipText(textArray, keyColor) {
    const string = textArray[0];

    const parts = string.split(':');

    let key = parts[0];

    if (key === 'revenue') {
      key = 'total';
    }

    const value = parts[1];

    const container = document.createElement('div');
    container.classList.add('font-medium');
    container.classList.add('text-sm');

    const keyElement = document.createElement('span');
    keyElement.textContent = key.charAt(0).toUpperCase() + key.slice(1);
    keyElement.style.color = keyColor;

    const valueElement = document.createElement('span');
    valueElement.textContent = ` ${this.currencyValue} ${value}`;
    valueElement.style.color = this.colors['font-grey'];

    container.appendChild(keyElement);
    container.appendChild(valueElement);

    return container;
  }

  _createDatasetConfig({
    label,
    data,
    bgColorTop,
    bgColorBottom,
    borderColor
  }) {
    return {
      label: label,
      data: data,
      fill: true,
      borderColor: borderColor,
      borderWidth: 1,
      pointRadius: 4,
      hitRadius: 10,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverRadius: 4,
      pointHoverBorderWidth: 4,
      pointHoverBackgroundColor: this.colors['white'],
      pointHoverBorderColor: borderColor,
      backgroundColor: (context) => {
        const bgColor = [bgColorTop, bgColorBottom];
        // if the area for the chart has not yet been created, then we interrupt the function to avoid errors
        if (!context.chart.chartArea) {
          return;
        }

        const {
          ctx,
          chartArea: { top, bottom }
        } = context.chart;
        const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);

        gradientBg.addColorStop(0, bgColor[0]);
        gradientBg.addColorStop(1, bgColor[1]);

        return gradientBg;
      }
    };
  }

  _createScaleConfig(gridColor, isGridDisplayed) {
    return {
      grid: {
        color: gridColor,
        display: isGridDisplayed,
        drawTicks: false
      },
      ticks: {
        color: this.colors['font-grey'],
        padding: 12,
        align: 'inner',
        font: {
          family: this.font,
          size: 12
        }
      },
      border: { color: gridColor }
    };
  }

  // Formatting labels for a chart
  formatLabels(labelsArray, granularity) {
    const formattedLabels = labelsArray.map((label) => {
      if (granularity === 'day') {
        return moment(label).format('DD.MM');
      } else if (granularity === 'hour') {
        return moment(label).format('HH:mm');
      } else if (granularity === 'month') {
        return moment(label).format('MMMM');
      } else if (granularity === 'year') {
        return moment(label).format('MMMM YYYY');
      } else {
        return label;
      }
    });

    return formattedLabels;
  }

  // Get or create tooltip element
  _getOrCreateTooltip(chart, isChangePosition) {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    // If the triangle is already in the element, we remove it
    if (tooltipEl && tooltipEl.querySelector('.chart-tooltip-triangle')) {
      const triangle = tooltipEl.querySelector('.chart-tooltip-triangle');
      triangle.parentNode.removeChild(triangle);
    }

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.classList.add('chart-tooltip');
  
      const table = document.createElement('table');
      table.classList.add('m-0');
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    // Create triangle for tooltip
    const triangle = document.createElement('div');
    triangle.classList.add('chart-tooltip-triangle');
    if (isChangePosition) {
      triangle.classList.remove('chart-tooltip-triangle-right');
      triangle.classList.add('chart-tooltip-triangle-left');
    } else {
      triangle.classList.remove('chart-tooltip-triangle-left');
      triangle.classList.add('chart-tooltip-triangle-right');
    }

    tooltipEl.appendChild(triangle);
  
    return tooltipEl;
  };

  // Create and return callback for create custom tooltip block
  _createExternalTooltipHandler() {
    // Custom tooltip plugin block
    return (context) => {
      const { chart, tooltip } = context;
      const chartWidth = chart.canvas.offsetWidth;
      const isNearRightEdge = tooltip.caretX > chartWidth / 2;
      const isChangePosition = isNearRightEdge && tooltip.caretX + 144 > chartWidth;

      const tooltipEl = this._getOrCreateTooltip(chart, isChangePosition);
      const tooltipWidth = tooltipEl.offsetWidth;

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
    
      // Set Text
      if (tooltip.body) {
        const tooltipKeyColor = tooltip.dataPoints[0].dataset.borderColor;
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body) => {
    
          const tr = document.createElement('tr');
          tr.classList.add('border-0');
    
          const td = document.createElement('td');
          td.classList.add('border-0', 'py-0', 'px-1.5');
    
          const formattedText = this._formatTooltipText(body, tooltipKeyColor);
    
          td.appendChild(formattedText);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });
    
        const tableRoot = tooltipEl.querySelector('table');
    
        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }
        
        // Add new children
        tableRoot.appendChild(tableBody);
      }
      const { left: positionX, top: positionY } = chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      // If the tooltip is at the right edge of the graph, then position it to the left of the point
      if (isChangePosition) {
        tooltipEl.style.left = `${positionX + tooltip.caretX - tooltipWidth + 50}px`;
      } 
      // Position it to the right of the point
      else {
        tooltipEl.style.left = `${positionX + tooltip.caretX + 90}px`;
      }
      tooltipEl.style.top = `${positionY + tooltip.caretY - 16}px`;
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
    };
  }
}
