import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];
  static values = { selectedTab: String };
  
  connect() {
    this.inputTargets.forEach((input) => {
      if (input.value === this.selectedTabValue) {
        input.parentElement.querySelector('label').classList.add('active');
      }
    });
  }

  changeTab(event) {
    this.inputTargets.forEach((input) => {
      input.parentElement.querySelector('label').classList.remove('active');
    });
    event.target.parentElement.querySelector('label').classList.add('active');
  }
}
