import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['favoriteBtn'];
  static values = { url: String };

  handleClick(event) {
    if (event.target.closest('.table-favorite') === this.favoriteBtnTarget) {
      return;
    } else {
      window.location.href = this.urlValue;
    }
  }
}
