import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'inAppMenu',
    'externalMenu',
    'modal',
    'selector'
  ];

  static values = {
    isMenuPresent: { type: Boolean, default: false },
    isMenuUrlPresent: { type: Boolean, default: false },
    inAppMenuHtml: String,
    externalMenuHtml: String
  };

  onDisplayMenuClick(e) {
    if (!(this.isMenuPresentValue || this.isMenuUrlPresentValue)) {
      e.stopImmediatePropagation();
  
      this.modalTarget.classList.remove('hidden');
    }
  }

  hideModal(e) {
    e.preventDefault();

    this.modalTarget.classList.add('hidden');
  }

  handleMenuDisplayTypeChange(e) {
    const targetValue = e.target.value;
    const isBothMenuPresent = this.isMenuPresentValue && this.isMenuUrlPresentValue;
  
    if (targetValue === 'in_app') {
      this.inAppMenuTarget.classList.remove('hidden');
      this.externalMenuTarget.classList.add('hidden');
    } else {
      this.inAppMenuTarget.classList.add('hidden');
      this.externalMenuTarget.classList.remove('hidden');
    }

    if (isBothMenuPresent) {
      this.selectorTarget.submit();
    }
  }
}
