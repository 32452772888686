import { v4 as uuidv4 } from 'uuid';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../stylesheets/tailwind';

export const getOrCreateUid = () => {
  let userId = localStorage.getItem('userId');

  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }

  return userId;
};

export const getTailwindTheme = () => {
  const fullConfig = resolveConfig(tailwindConfig);

  return fullConfig.theme;
};
