import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import Dropzone from 'dropzone';

import { findElement, getMetaValue, insertAfter, removeElement } from './helpers';

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ['input', 'defaultScreen', 'loading', 'error'];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }
  
  bindEvents() {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on('success', () => {
      this.form.submit();
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }

  get form() {
    return this.element.closest('form');
  }

  get submitButton() {
    return findElement(this.form, 'input[type=submit], button[type=submit]');
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();

    if (this.hasDefaultScreenTarget) this.source.defaultScreenTarget.classList.add('hidden');
    if (this.hasLoadingTarget) this.source.loadingTarget.classList.remove('hidden');
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.source.errorTarget.classList.remove('hidden');
        this.source.loadingTarget.classList.add('hidden');
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  // Private
  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR() {
    this.emitDropzoneUploading();
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    createImageThumbnails: false,
    disablePreviews: true,
    autoQueue: false
  });
}
