import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'page' // a page that is hidden until it has fully loaded
  ];

  loadEventListenerCallbacks = new Map();

  connect() {
    this.addLoadListener(this);
  }

  disconnect() {
    this.removeLoadListener(this);
  }

  addLoadListener(context) {
    // Show page if the page is already loaded and break function
    if (document.readyState === 'complete') {
      this._showPage.call(context);
      return;
    } else {
      const callback = this._showPage.bind(context);
      this.loadEventListenerCallbacks.set(context, callback);
      window.addEventListener('load', callback);
    }
  }

  removeLoadListener(context) {
    const callback = this.loadEventListenerCallbacks.get(context);
    if (callback) {
      window.removeEventListener('load', callback);
      this.loadEventListenerCallbacks.delete(context);
    }
  }

  _showPage() {
    this.pageTarget.classList.remove('hidden');
  }
}
