import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['loader'];

  loadEventListenerCallback = null;

  connect() {
    // Hide the loader immediately if the page is already loaded
    if (document.readyState === 'complete') {
      this._hideLoader(); 
    } else {
      this.loadEventListenerCallback = this._hideLoader.bind(this); 
      window.addEventListener('load', this.loadEventListenerCallback);
    }
  }

  disconnect() {
    this._hideLoader();
    window.removeEventListener('load', this.loadEventListenerCallback);
  }

  _hideLoader() {
    this.loaderTarget.classList.add('hidden');
  }
}
