import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'span'];

  hidden = true;
  hideText = 'hide';
  showText = 'show';

  connect() {
    this.spanTarget.innerText = this.showText;
  }

  toggle() {
    if (this.hidden) {
      this.inputTarget.type = 'text';
      this.spanTarget.innerText = this.hideText;
    } else {
      this.inputTarget.type = 'password';
      this.spanTarget.innerText = this.showText;
    }

    this.hidden = !this.hidden;
  }
}
