import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggler'];

  closeMenu(event) {
    if (!this.hasTogglerTarget) return;
    if (event.target.closest('.dropdown') || event.target.href && event.target.href !== '') return;
    event.preventDefault();
    if (event.target.closest('.burger-cta') || event.target.closest('.nav-menu-close')) return this.togglerTarget.checked = !this.togglerTarget.checked;
    if (!this.togglerTarget.checked || event.target.closest('.menu')) return;
    this.togglerTarget.checked = false;
  }
}
