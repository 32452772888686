const defaultTheme = require('tailwindcss/defaultTheme');
const { colors: defaultColors, width, fontSize } = defaultTheme;

module.exports = {
  ...defaultTheme,
  mode: 'jit',
  purge: [
    './app/**/*.html.erb',
    './app/**/*.html.haml',
    './app/**/*.rb',
    './app/assets/javascripts/**/*.js',
    './app/assets/images/**/*.svg',
    './app/javascript/**/*.js',
    './config/initializers/simple_form*.rb'
  ],
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    colors: {
      ...defaultColors,
      primary: '#2C3F65',
      secondary: '#E1B173',
      orange: '#F69D16',
      'warning-salmon': '#ED8880',
      'warning-red': '#CE0F00',
      info: '#7698C8',
      success: '#6DC2B6',
      grey: '#CCCCCC',
      disabled: '#CCCCCC',
      black: '#212121',
      white: '#FFFFFF',
      'deep-blue': '#203359',
      'medium-blue': '#2C3F65',
      'light-grey': '#F9F9F9',
      'medium-grey': '#EBEBEB',
      'grey-blue': '#F5F6FA',
      'font-grey': '#666E80',
      // slice colors
      black: '#212121',
      'blue-chart-cell': '#EFF4FF',
      'blue-deep': '#1B2438',
      'blue-medium': '#203359',
      blue: '#7698C8',
      'blue-light': '#616B7D',
      'blue-highlight': '#CBE4FF',
      gold: '#FFB555',
      green: '#9BDC65',
      'green-light': '#E0F6BD',
      'pink-bg-chart-t': 'rgba(248, 140, 162, 0.4)',
      'pink-bg-chart-b': 'rgba(248, 140, 162, 0)',
      'pink-deep': '#FF3295',
      'pink-light': '#F88CA2',
      'pink-lighter': '#FEE7EC',
      'pink-highlight': '#FFF5F7',
      'pink-dull': '#FFE8EC',
      'red-medium': '#EB425F',
      'violet-medium': '#9D2FF2',
      'violet-bg-chart-t': 'rgba(173, 92, 236, 0.4)',
      'violet-bg-chart-b': 'rgba(201, 126, 236, 0)',
      'blur-background': 'rgba(255, 255, 255, 0.70)',
      'logo-background': 'rgba(224, 246, 189, 0.4)',
      'modal-background': 'rgba(1, 1, 1, 0.8)'
    },
    extend: {
      boxShadow: {
        dropdown: '0px 4px 56px rgba(32, 51, 89, 0.08)',
        'chart-tooltip': '0px 2px 12px rgba(0, 0, 0, 0.3)'
      },
      height: {
        15.5: '3.875rem',
        25: '6.25rem'
      },
      borderWidth: {
        10: '0.625rem',
        14: '0.875rem'
      },
      minWidth: {
        '1/2': '50%',
        '1/3': '33.333333%'
      },
      spacing: {
        4.5: '1.125rem',
        5.5: '1.375rem',
        7.5: '1.875rem',
        15: '3.75rem',
        17: '4.25rem',
        23: '5.75rem',
        42.5: '10.625rem',
        62: '15.5rem',
        79.5: '19.875rem',
        90: '350px' // TODO: Temporary fix for menu display form label
      },
      width: {
        6.5: '1.625rem',
        19: '4.75rem',
        35: '8.75rem',
        39.5: '9.875rem',
        83: '21.75rem'
      },
      backgroundImage: {
        'arrow-left': "url('arrow_left.svg')",
        'arrow-right': "url('arrow_right.svg')"
      }
    },
    fontFamily: {
      primary: ['"Noto Sans"', 'ui-sans-serif', 'system-ui'],
      secondary: ['"Maven Pro"', 'ui-sans-serif', 'system-ui'],
      'primary-bo': ['"DM Sans"', 'ui-sans-serif', 'system-ui'],
      'secondary-bo': ['"Space Grotesk"', 'ui-sans-serif', 'system-ui']
    },
    fontSize: {
      ...fontSize,
      xxs: ['10px', '12px'],
      '4.5xl': ['40px', '52px']
    }
  },
  variants: { extend: {} },
  plugins: []
};
