import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'card', // card element
    'cardText' // element with text containing currency with amount
  ];

  static values = { currency: String };

  setDataToCards({ detail }) {
    const { tipsData, revenueData, averageBillData, countOrdersData } = detail;
    this.cardTargets.forEach((target) => {
      const cardId = target.id;
      const cardText = this.cardTextTargets.find(target => target.id === cardId);

      // Check if the card id matches the value we need
      if (cardId === 'orders') {
        let ordersCount = countOrdersData.reduce((sum, value) => {
          return value ? sum + (+value) : sum;
        }, 0);

        cardText.innerText = ordersCount;
      } else if (cardId === 'average_bill') {
        cardText.innerText = this._addTheSum(averageBillData);
      } else if (cardId === 'tips') {
        cardText.innerText = this._addTheSum(tipsData);
      } else if (cardId === 'revenue') {
        cardText.innerText = this._addTheSum(revenueData);
      }

    });
  }

  _addTheSum(sumArray) {
    const amount = sumArray.reduce((acc, sum) => {
      return sum ? acc + parseFloat(sum): acc;
    }, 0);

    return `${this.currencyValue} ${amount.toFixed(2)}`;
  }
}
