import { Controller } from 'stimulus';

export default class extends Controller {
  modal = null;

  connect() {
    const modal_has_error = document.getElementsByClassName('full-error')[0];
    if (modal_has_error) {
      const modal = modal_has_error.closest('.modal');
      if (!modal) return;
      this._open(modal);
    }
  }

  toggle(event) {
    this.modal = document.getElementById(event.params.id);
    if (!this.modal) this.modal = event.target.closest('.modal');
    this._toggleModal();
  }

  hide(event) {
    this.modal = event.target.closest('.modal');
    if (this._is_opened() && event && (event.target.contains(document.getElementById(event.params.id)))) {
      this._toggleModal(event);
    }
  }

  _is_opened() {
    return this.modal.classList.contains('show');
  }

  _toggleModal() {
    this._is_opened() ? this._close() : this._open();
  }

  _close() {
    this.modal.classList.remove('show');
    this.modal.classList.add('hidden');
  }

  _open() {
    this.modal.classList.add('show');
    this.modal.classList.remove('hidden');
  }
}
