import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['items', 'seeMoreButton', 'seeLessButton'];

  showMoreOrLessItems(event) {
    if (event.target.checked) {
      this.itemsTarget.classList.remove('hidden');
      this.seeMoreButtonTarget.classList.add('hidden');
      this.seeLessButtonTarget.classList.remove('hidden');
    } else {
      this.itemsTarget.classList.add('hidden');
      this.seeMoreButtonTarget.classList.remove('hidden');
      this.seeLessButtonTarget.classList.add('hidden');
    }
  }
}
