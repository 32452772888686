import confetti from 'canvas-confetti';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
      'radio',
      'inputTooltip',
      'customAmountInput'
  ];

  disconnect() {
    confetti.reset();
  }

  handleRadioChange(event) {
    const input = event.target.closest('.tip-box').querySelector('input');

    const clickedElementId = input.id;

    // Deselect tip
    if (input.checked) {
      event.preventDefault();
      document.getElementById(clickedElementId).checked = false;
    } 
    // Explode confetti
    else {
      const emojiMapping = {
        'tip-1': ['💚', '🩷'],
        'tip-2': ['💖', '🩷'],
        'tip-3': ['🍉', '😍']
      };
      
      const emojis = emojiMapping[clickedElementId] ?? ['😭', '💔'];
      
      this._explodeConfetti(emojis, [2, 4]);
    }
    
    this._clearCustomAmountInput();
  }

  clearRadioButtons(e) {
    this.radioTargets.forEach(radio => radio.checked = false);
    if (e.target.value > 0) {
      this.showInputTooltip();
      this.customAmountInputTarget.classList.add('custom-amount-input-with-value');
    } 
    else {
      this.hideInputTooltip();
      this.customAmountInputTarget.classList.remove('custom-amount-input-with-value');
    }
  }

  _clearCustomAmountInput() {
    this.customAmountInputTarget.classList.remove('custom-amount-input-with-value');
    this.customAmountInputTarget.value = '';
    this.hideInputTooltip();
  }

  hideInputTooltip() {
    this.inputTooltipTarget.classList.add('hidden');
  }

  showInputTooltip() {
    this.inputTooltipTarget.classList.remove('hidden');
  }

  triggerConfettiOnBlur(e) {
    // Break the function and do not show confetti
    if (e.target.value <= 0) {
      return;
    }
    this._explodeConfetti(['🍉'], [2, 4]);
  }

  _explodeConfetti(emojisArray, sizesArray) {

    const confettiOptions = {
      particleCount: 7,
      ticks: 1000,
      spread: 55,
      flat: true,
      startVelocity: 40,
      gravity: 3,
      angle: 60,
      origin: { x: 0, y: 0.6 },
      drift: 0.5
    };

    for (const size of sizesArray) {
      const emojisWithSize = emojisArray.map(emoji =>
        confetti.shapeFromText({ text: emoji, scalar: size }));

      const confettiParams = {
        ...confettiOptions,
        shapes: emojisWithSize,
        scalar: size
      };

      // Confetti on the left
      confetti(confettiParams);

      confettiParams.angle = 120;
      confettiParams.drift = -0.5;
      confettiParams.origin = { x: 1, y: 0.6 },

      // Confetti on the right
      confetti(confettiParams);
    }
  }
}
