import TableDetailsBaseController from './table_details/table_details_base_controller';

export default class extends TableDetailsBaseController {
  static targets = [
    ...TableDetailsBaseController.targets,
    'splitModal',
    'totalCharges',
    'totalTaxes',
    'totalCountWithTaxes'
  ];

  static values = { leftToPay: Number };

  connect() {
    this.addLoadListener(this);
    this.refreshAmount();
  }

  disconnect() {
    this.removeLoadListener(this);
  }

  refreshAmount() {
    this.totalAmount = this.leftToPayValue;
    
    this.yourBillTarget.innerText = (+this.totalAmount).toFixed(2);

    this._precessCheckoutFee();
    this._processTips();

    this.amountToPayTarget.innerText = (+this.totalAmount).toFixed(2);

    this.totalAmount = this._getTotalValue();
    this._precessCheckoutFee();
    this.totalCountWithTaxesTarget.innerText = (+this.totalAmount).toFixed(2);
  }

  hideModal(event) {
    event.preventDefault();

    this.splitModalTarget.classList.remove('fixed');
    this.splitModalTarget.classList.add('hidden');
  }

  showModal(event) {
    event.preventDefault();

    this.splitModalTarget.classList.remove('hidden');
    this.splitModalTarget.classList.add('fixed');
  }
}
