import { Controller } from 'stimulus';

// Connects to data-controller="form"
export default class extends Controller {

  matchNumeric(e) {
    var regex = /\d|\./;
    var key;

    if (e.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain');
    } else {
      key = String.fromCharCode(e.keyCode || e.which);
    }

    if (!regex.test(key) ) {
      e.returnValue = false;
      e.preventDefault();
      return false;
    }
  }
}
