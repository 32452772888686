import TableDetailsBaseController from './table_details/table_details_base_controller';

export default class extends TableDetailsBaseController {
  static targets = [
    ...TableDetailsBaseController.targets,
    'splitter',
    'equallySplit',
    'inputPeopleAtTable',
    'inputPeopleToPayFor'
  ];

  static values = {
    totalInitial: Number,
    leftToPay: Number
  };

  connect() {
    this.addLoadListener(this);
    this.totalAmount = this.hasLeftToPayValue ? this.leftToPayValue : 0;
    this._precessCheckoutFee();
    this.userWantsToPay = this.totalAmount;
    this.userLeftToPayAmount = this.totalAmount;
    this.refreshAmount();
  }

  disconnect() {
    this.removeLoadListener(this);
  }

  handleBillTypeChange(e) {
    this.totalValue = e.target.value === 'left_to_pay' ? this.leftToPayValue : this.totalInitialValue;

    this.refreshAmount();
  }

  refreshAmount() {
    this.totalAmount = 0;

    this.totalAmount =
      parseFloat((this.totalValue / this.inputPeopleAtTableTarget.value) * this.inputPeopleToPayForTarget.value, 2);

    this.yourBillTarget.innerText = (+this.totalAmount).toFixed(2);

    this._precessCheckoutFee();
    this._processTips();

    this.amountToPayTargets.forEach(amountToPayTarget => amountToPayTarget.innerText = (+this.totalAmount).toFixed(2));

    this.userWantsToPay = this.totalAmount;
  }

  refreshAmountWithWhatsLeftToPay() {
    const billRestButton = document.getElementById('bill-rest');

    if (this.userWantsToPay < this.userLeftToPayAmount) {
      return;
    }

    if (this.hasLeftToPayValue) {
      if (billRestButton) {
        billRestButton.checked = true;
      }
      this.totalValue = this.leftToPayValue;

      this.dispatch('resetSplitterToDefault');
      this.refreshAmount();
    } 
  }
}
