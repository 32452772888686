import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form', 'cardHolderName'];

  static values = {
    table: String,
    locationSlug: String,
    paymentIntent: String
  };

  stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute('content');
  stripe = Stripe(this.stripe_key);
  elements = {};
  clientSecret = '';
  cardNumberElement = {};

  validCardHolderName = false;
  validCardNumber = false;
  validCardExpiry = false;
  validCardCvc = false;

  connect() {
    this._setupStripe();
  }

  cardHolderNameChange(event) {
    this._validateCardHolderName(event.target);
  }

  _validateCardHolderName(element) {
    const isValid = element.value.length > 0;
    this.validCardHolderName = isValid;
    const event = {};
    if (!isValid) {
      event.error = { message: 'Card holder name is required' // TODO: i18n
      };
    }
    this._showError(event, 'card-name');
  }

  _setupStripe() {
    Rails.ajax({
      url: `/locations/${this.locationSlugValue}/tables/${this.tableValue}/payments/${this.paymentIntentValue}`,
      dataType: 'json',
      type: 'GET',
      success: ({ stripe_client_secret, js_total_amount: { total_amount_cents, currency } }) => {
        this.clientSecret = stripe_client_secret;
        this.elements = this.stripe.elements({
          clientSecret: this.clientSecret,
          locale: 'en'
        });

        const white = '#ffffff';
        const style = {
          base: { '::placeholder': { color: '#7698C8' } },
          invalid: {
            color: '#CE0F00',
            '::placeholder': { color: white },
            iconColor: white
          }
        };

        const cardNumberElement = this.elements.create('cardNumber', {
          showIcon: true,
          style: style
        });
        this.cardNumberElement = cardNumberElement;
        cardNumberElement.mount('#card-number');

        const cardExpiryElement = this.elements.create('cardExpiry', { style: style });
        cardExpiryElement.mount('#card-expiry');

        const cardCvcElement = this.elements.create('cardCvc', { style: style });
        cardCvcElement.mount('#card-cvc');

        const elements = [
          [cardNumberElement, 'card-number'],
          [cardExpiryElement, 'card-expiry'],
          [cardCvcElement, 'card-cvc']
        ];

        elements.forEach(([element, cssClass]) => {
          element.on('change', (event) => {
            const isValid = event.complete && (!event.error || !event.empty);
            switch (cssClass) {
              case 'card-number':
                this.validCardNumber = isValid;
                break;
              case 'card-expiry':
                this.validCardExpiry = isValid;
                break;
              case 'card-cvc':
                this.validCardCvc = isValid;
                break;
            }

            this._showError(event, cssClass);
          });
        });

        const paymentRequest = this.stripe.paymentRequest({
          country: 'AE',
          currency: currency,
          total: {
            label: 'Total',
            amount: total_amount_cents
          },
          requestPayerName: true,
          requestPayerEmail: true
        });

        const prButton = this.elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
          style: { paymentRequestButton: { height: '56px' } }
        });

        paymentRequest.on('paymentmethod', (event) => {
          // Confirm the PaymentIntent with the payment method returned from the payment request.
          this.stripe
            .confirmCardPayment(
              this.clientSecret,
              { payment_method: event.paymentMethod.id },
              { handleActions: false }
            )
            .then(({ error }) => {
              if (error) {
                // Report to the browser that the payment failed.
                event.complete('fail');
                this._showMessage(error.message);
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                event.complete('success');
                // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
                this.stripe
                  .confirmCardPayment(this.clientSecret)
                  .then(() => this.formTarget.submit());
              }
            });
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            prButton.mount('#payment-request-button');
            document.getElementById('divider').classList.remove('hidden');
          } else {
            document.getElementById('payment-request-button').style.display =
              'none';
          }
        });
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this._setLoading(true);

    this._validateCardHolderName(this.cardHolderNameTarget);

    if (this.validCardHolderName) {
      // Complete a payment intent
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumberElement,
            billing_details: { name: this.cardHolderNameTarget.value }
          }
        })
        .then(({ error }) => {
          if (error) {
            this._showMessage(error.message);
          } else {
            this.formTarget.submit();
          }
        });
    } else {
      this._setLoading(false);
    }
  }

  _setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.getElementById('submit-btn').disabled = true;
      document.getElementById('submit-btn').classList.add('cursor-wait');
      document.getElementById('button-text').classList.add('hidden');
      document.getElementById('spinner').classList.remove('hidden');
    } else {
      document.getElementById('submit-btn').disabled = false;
      document.getElementById('spinner').classList.add('hidden');
      document.getElementById('button-text').classList.remove('hidden');
      document.getElementById('submit-btn').classList.remove('cursor-wait');
    }
  }

  _showMessage(messageText) {
    this._setLoading(false);
    const messageContainer = document.getElementById('payment-message');

    messageContainer.classList.remove('hidden');
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add('hidden');
      messageText.textContent = '';
    }, 4000);
  }

  _showError(event, id) {
    var displayError = document.getElementById(`${id}-errors`);
    var inputElement = document.getElementById(id);
    if (event.error) {
      displayError.textContent = event.error.message;
      displayError.classList.remove('hidden');
      inputElement.classList.add('error');
    } else {
      displayError.textContent = '';
      displayError.classList.add('hidden');
      inputElement.classList.remove('error');
    }
    this._handleSubmitBtnStatus();
  }

  _handleSubmitBtnStatus() {
    if (this.validCardHolderName && this.validCardNumber && this.validCardExpiry && this.validCardCvc) {
      document.getElementById('submit-btn').classList.remove('btn-disabled');
      document.getElementById('submit-btn').disabled = false;
    } else {
      document.getElementById('submit-btn').classList.add('btn-disabled');
      document.getElementById('submit-btn').disabled = true;
    }
  }
};
