import getRads from './getRads';

// Render pie of chart
const renderPie = (ctx, radius, percent) => {
  const centerX = 0;
  const centerY = 0;
  const startAngle = 0;
  const endAngle = getRads(360 * percent);

  ctx.save();

  // Move ctx to proper position
  ctx.translate(radius, radius);
  ctx.rotate(getRads(-90));

  // Render pie
  ctx.beginPath();
  ctx.moveTo(centerX, centerY);
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.closePath();
  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
  ctx.fill();

  ctx.restore();
};

// Render chart pie with animation between two percent states
const renderChartPie = (canvas, startPercent, endPercent, duration = 200) => {
  const ctx = canvas.getContext('2d');

  const startTime = performance.now();

  function animate(currentTime) {
    // Get animation progress of current render
    const timeDelta = currentTime - startTime;
    const progress = Math.min(timeDelta / duration, 1);

    // Clear ctx and render new frame with current percent state
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const chartRadius = canvas.width / 2;
    const percentStep = (endPercent - startPercent) * progress;
    const nextPercentStep = startPercent + percentStep;
    renderPie(ctx, chartRadius, nextPercentStep);

    // Keep rendering if animation is not finished
    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
};

export default renderChartPie;
