import { Controller } from 'stimulus';

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ['flash'];

  _removeTarget(target) {
    setTimeout(() => {
      target.remove();
    }, 150);
  }

  flashTargetConnected(target) {
    setTimeout(() => {
      target.style.opacity = 0;
      this._removeTarget(target);
    }, 3000);
  }

  close(event) {
    event.target.closest('div[role="flash"]').remove();
  }
}
