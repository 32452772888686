import Chart from 'chart.js/auto';
import { useMeta } from 'stimulus-use';

import { getTailwindTheme } from '../helpers/helpers';

import ChartHelpersController from './chart_helpers_controller';

export default class extends ChartHelpersController {
  static targets = [
    ...ChartHelpersController.targets,
    'chart', // canvas element in which the chart is created
    'loader' // element with loading, which is displayed when data is loaded on the chart
  ];

  theme = getTailwindTheme();
  colors = this.theme.colors;
  font = this.theme.fontFamily['primary-bo'][0];

  connect() {
    useMeta(this);

    // Getting data from meta tags
    this.jwtToken = this.cubejsJwtTokenMeta;
    this.host = this.cubejsHostMeta;
    this.dispatch('chartConnected');
  }

  async renderChart({ detail: { dateRange, granularity } }) {
    const queries = [
      {
        valueKey: 'tips',
        dateValueKey: 'created_at'
      },
      {
        valueKey: 'total_revenue',
        dateValueKey: 'created_at'
      },
      {
        valueKey: 'average_bill',
        dateValueKey: 'created_at'
      },
      {
        valueKey: 'count',
        dateValueKey: 'created_at'
      }
    ];

    const resultSetPromises = queries.map(query => this.fetchCubeData(this.createDataConfig({
      valueKey: query.valueKey,
      dateValueKey: query.dateValueKey,
      dateRange,
      granularity
    })));
    
    const resultSets = await Promise.all(resultSetPromises);

    const chartData = resultSets.map((resultSet, index) => this.createChartJsData({
      resultSet,
      valueKey: queries[index].valueKey,
      dateValueKey: queries[index].dateValueKey,
      granularity
    }));

    const [tipsData, revenueData, averageBillData, countOrdersData] = chartData;

    const hoverFirstChartStates = [];
    const hoverSecondChartStates = [];
    
    const chartCanvas = this.chartTarget;

    this.loaderTarget.classList.add('hidden');

    const labels = this.formatLabels(revenueData.labels, granularity);

    // Divide sums by 100, since the amounts come in cents
    const tipsDataValues = tipsData.datasets.map(sum => sum / 100);
    const revenueDataValues = revenueData.datasets.map(sum => sum / 100);
    const averageBillDataValues = averageBillData.datasets.map(sum => sum / 100);

    this.dispatch('cubeDataLoaded', {
      detail: {
        tipsData: tipsDataValues,
        revenueData: revenueDataValues,
        averageBillData: averageBillDataValues,
        countOrdersData: countOrdersData.datasets
      }
    });

    // Get or create list with legend elements
    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer.querySelector('ul');
    
      if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.classList.add('flex', 'flex-row', 'm-0', 'p-0');
    
        legendContainer.appendChild(listContainer);
      }
    
      return listContainer;
    };
    
    // Custom legend plugin block
    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);
    
        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }
    
        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
    
        items.forEach((item) => {
          const li = document.createElement('li');
          li.classList.add('chart-legend-list-el');
    
          li.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
            chart.update();
          };
    
          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.strokeStyle;
          boxSpan.classList.add('chart-legend-circle');

          // Gradient line
          const gradientLine = document.createElement('div');
          gradientLine.classList.add('chart-legend-line');
          gradientLine.style.background = `linear-gradient(to right, ${item.strokeStyle} -200%, white)`;
    
          // Text
          const textContainer = document.createElement('p');
          textContainer.classList.add('chart-legend-text');
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
    
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);
    
          li.appendChild(boxSpan);
          li.appendChild(gradientLine);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    };

    // chartAreaBorder plugin block
    const chartAreaBorder = {
      id: 'chartAreaBorder',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, width, height } } = chart;
        ctx.save();
        ctx.strokeStyle = options.borderColor;
        ctx.lineWidth = options.borderWidth;
        ctx.setLineDash(options.borderDash || []);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.strokeRect(left, top, width, height);
        ctx.restore();
      }
    };

    // datapointLines plugin block
    const datapointLines = {
      id: 'datapointLines',
      afterEvent(chart, args) {
        const xCursor = args.event.x;
        const yCursor = args.event.y;
        const mouseEvent = args.event.type;
        if (mouseEvent === 'mousemove') {
          for (let j = 0; j < chart._metasets.length; j++) {
            const hoverChartStates =
              j === 0 ? hoverFirstChartStates : hoverSecondChartStates;

            for (let i = 0; i < chart._metasets[j].data.length; i++) {
              const xMin = chart._metasets[j].data[i].x - 10;
              const xMax = chart._metasets[j].data[i].x + 10;
              const yMin = chart._metasets[j].data[i].y - 10;
              const yMax = chart._metasets[j].data[i].y + 10;
              
              // Checking that the cursor is inside a point
              if (
                xCursor >= xMin &&
                xCursor <= xMax &&
                yCursor >= yMin &&
                yCursor <= yMax
              ) {
                // 0 - the cursor is not over the point, 1 - the cursor is over the point
                if (hoverChartStates[i] === 0) {
                  hoverChartStates[i] = 1;
                } 
              } else {
                hoverChartStates[i] = 0;
              }
            }
          }
        }
      },
      beforeDatasetsDraw(chart) {
        const { ctx, chartArea: { bottom } } = chart;
        const tooltip = chart.tooltip;
        
        class Line {
          constructor(xCoor, yCoor) {
            this.width = xCoor;
            this.top = yCoor;
          }
          draw(ctx) {
            ctx.restore();
            ctx.beginPath();
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = tooltip.dataPoints?.[0].dataset.borderColor;
            ctx.setLineDash([6, 6]);
            ctx.moveTo(this.width, this.top);
            ctx.lineTo(this.width, bottom);
            ctx.stroke();
            ctx.save();
            
            ctx.setLineDash([]);
          }
        }

        // Processing points of the first chart
        for (let i = 0; i < hoverFirstChartStates.length; i++) {
          if (hoverFirstChartStates[i] === 1) {
            let drawLine = new Line(chart._metasets[0].data[i].x, chart._metasets[0].data[i].y);
            drawLine.draw(ctx);
          }
        }
        // Processing points of the second chart
        for (let i = 0; i < hoverSecondChartStates.length; i++) {
          if (hoverSecondChartStates[i] === 1) {
            let drawLine = new Line(chart._metasets[1].data[i].x, chart._metasets[1].data[i].y);
            drawLine.draw(ctx);
          }
        }
      }
    };

    const data = {
      labels,
      datasets: [
        this._createDatasetConfig({
          label: 'revenue',
          data: revenueDataValues,
          bgColorTop: this.colors['pink-bg-chart-t'],
          bgColorBottom: this.colors['pink-bg-chart-b'],
          borderColor: this.colors['pink-deep']
        }),
        this._createDatasetConfig({
          label: 'tips',
          data: tipsDataValues,
          bgColorTop: this.colors['violet-bg-chart-t'],
          bgColorBottom: this.colors['violet-bg-chart-b'],
          borderColor: this.colors['violet-medium']
        })
      ]
    };

    const config = {
      type: 'line',
      data,
      options: {
        responsive: true,
        scales: {
          x: this._createScaleConfig(this.colors['blue-chart-cell'], false),
          y: this._createScaleConfig(this.colors['blue-chart-cell'], true)
        },
        plugins: {
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: this._createExternalTooltipHandler()
          },
          chartAreaBorder: {
            borderColor: this.colors['blue-chart-cell'],
            borderWidth: 1
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend-container'
          },
          legend: { display: false }
        }
      },
      plugins: [chartAreaBorder, htmlLegendPlugin, this._createExternalTooltipHandler(), datapointLines]
    };

    new Chart(chartCanvas, config);
  }
}
