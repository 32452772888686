import { Controller } from 'stimulus';

import renderChartPie from '../utils/renderPieChart';

const getPayPercentText = percent => Math.floor(percent * 100) + '%';

export default class extends Controller {
  static targets = [
    'diagram',
    'payPercent',
    'inputPeopleAtTable',
    'buttonMinusPeopleAtTable',
    'buttonPlusPeopleAtTable',
    'inputPeopleToPayFor',
    'buttonMinusPeopleToPayFor',
    'buttonPlusPeopleToPayFor'
  ];

  connect() {
    if (!this.inputPeopleAtTableTarget.value) {
      this.inputPeopleAtTableTarget.value = parseInt(this.inputPeopleAtTableTarget.min, 10);
    }

    const percent = 1 / this.inputPeopleAtTableTarget.value;

    // Render chart
    renderChartPie(this.diagramTarget, percent, 0);
    // Set pay percent text
    this.payPercentTarget.innerText = getPayPercentText(percent);
    // Set buttons state
    this._setButtons();
  }

  _setButtons() {
    const peopleAtTable = parseInt(this.inputPeopleAtTableTarget.value, 10);
    const peopleToPayFor = parseInt(this.inputPeopleToPayForTarget.value, 10);

    // Set 'people at table' buttons
    const isButtonMinusPeopleAtTableDisabled = peopleAtTable === 1 || (peopleAtTable === peopleToPayFor);
    const isButtonPlusPeopleAtTableDisabled = peopleAtTable === 30;

    if (isButtonMinusPeopleAtTableDisabled) this.buttonMinusPeopleAtTableTarget.disabled = true;
    else if (this.buttonMinusPeopleAtTableTarget.disabled) this.buttonMinusPeopleAtTableTarget.disabled = false;
    if (isButtonPlusPeopleAtTableDisabled) this.buttonPlusPeopleAtTableTarget.disabled = true;
    else if (this.buttonPlusPeopleAtTableTarget.disabled) this.buttonPlusPeopleAtTableTarget.disabled = false;

    // Set 'people to pay for' buttons
    const isButtonMinusPeopleToPayForDisabled = peopleToPayFor === 1;
    const isButtonPlusPeopleToPayForDisabled = peopleToPayFor === peopleAtTable;

    if (isButtonMinusPeopleToPayForDisabled) this.buttonMinusPeopleToPayForTarget.disabled = true;
    else if (this.buttonMinusPeopleToPayForTarget.disabled) this.buttonMinusPeopleToPayForTarget.disabled = false;
    if (isButtonPlusPeopleToPayForDisabled) this.buttonPlusPeopleToPayForTarget.disabled = true;
    else if (this.buttonPlusPeopleToPayForTarget.disabled) this.buttonPlusPeopleToPayForTarget.disabled = false;
  }

  changePeopleNumber(operation, buttonsType) {
    const startPercent = (1 / this.inputPeopleAtTableTarget.value) * this.inputPeopleToPayForTarget.value;

    if (buttonsType === 'peopleAtTable') {
      if (operation === 'decrement') this.inputPeopleAtTableTarget.value--;
      else this.inputPeopleAtTableTarget.value++;
    } else {
      if (operation === 'decrement') this.inputPeopleToPayForTarget.value--;
      else this.inputPeopleToPayForTarget.value++;
    }

    const endPercent = (1 / this.inputPeopleAtTableTarget.value) * this.inputPeopleToPayForTarget.value;
    const startPercentToRender = 1 - startPercent;
    const endPercentToRender = 1 - endPercent;

    // Render chart
    renderChartPie(this.diagramTarget, startPercentToRender, endPercentToRender);
    // Set pay percent text
    this.payPercentTarget.innerText = getPayPercentText(endPercent);
    // Set buttons state
    this._setButtons();
  }

  // People at table switcher
  decrementPeopleAtTable(event) {
    event.preventDefault();

    this.changePeopleNumber('decrement', 'peopleAtTable');
  }

  incrementPeopleAtTable(event) {
    event.preventDefault();

    this.changePeopleNumber('increment', 'peopleAtTable');
  }

  // People to pay for switcher
  decrementPeopleToPayFor(event) {
    event.preventDefault();

    this.changePeopleNumber('decrement', 'peopleToPayFor');
  }

  incrementPeopleToPayFor(event) {
    event.preventDefault();

    this.changePeopleNumber('increment', 'peopleToPayFor');
  }

  resetSplitterToDefault() {
    this.inputPeopleAtTableTarget.value = parseInt(this.inputPeopleAtTableTarget.min, 10);
    this.inputPeopleToPayForTarget.value = parseInt(this.inputPeopleToPayForTarget.min, 10);
  
    const percent = 1 / this.inputPeopleAtTableTarget.value;
  
    renderChartPie(this.diagramTarget, percent, 0);
    
    this.payPercentTarget.innerText = getPayPercentText(percent);
    
    this._setButtons();
  }
}
