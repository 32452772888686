import { Controller } from 'stimulus';
import cubejs from '@cubejs-client/core';

export default class extends Controller {
  static metaNames = ['cubejs_host', 'cubejs_jwt_token'];

  jwtToken = null;
  host = null;

  // Getting data from a cube
  async fetchCubeData(query) {
    const cubeJsBaseUrl = `${this.host}/cubejs-api/v1`;

    const cubejsApi = cubejs(this.jwtToken, { apiUrl: cubeJsBaseUrl });

    const resultSet = await cubejsApi.load(query);

    return resultSet.tablePivot({ fillMissingDates: true });
  }

  // Creating a config with data to be sent to the cube
  createDataConfig({ valueKey, dateValueKey, dateRange, granularity }) {
    return {
      measures: [`orders.${valueKey}`],
      timeDimensions: [
        {
          dimension: `orders.${dateValueKey}`,
          dateRange,
          granularity
        }
      ]
    };
  }

  // Create datasets and labels for chart
  createChartJsData({ resultSet, valueKey, dateValueKey, granularity }) {
    return {
      datasets: resultSet.map(data => data[`orders.${valueKey}`]),
      labels: resultSet.map(data => data[`orders.${dateValueKey}.${granularity}`])
    };
  }
}
