import { Controller } from 'stimulus';
import Tooltip from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = ['tooltip'];
  connect() {
    new Tooltip(this.tooltipTarget, {
      placement: 'top',
      theme: 'custom'
    });
  }
}
