import { Controller } from 'stimulus';
import { getOrCreateUid } from '../helpers/helpers';

export default class extends Controller {
  static targets = [
    'addOrRemoveBtn', // add/remove item button
    'item' // list item
  ];

  static values = {
    isActive: Boolean,
    itemId: String,
    itemUid: String,
    itemPrice: Number,
    tax: Number,
    charge: Number
  };

  userId = null;

  connect() {
    this.isActiveValueChanged();
    // Calculating the amount for selected items
    if (this.isActiveValue && this.itemUidValue && this.itemUidValue === this.userId) {
      this.dispatch('add', { detail: this._getData() });
    }
  }

  _getData() {
    return {
      id: this.itemIdValue,
      price: this.itemPriceValue,
      tax: this.taxValue,
      charge: this.chargeValue
    };
  }

  toggle(e) {
    e.preventDefault();

    if (this.isActiveValue === false) {
      this._activate(e.target);
    } else {
      this._deactivate(e.target);
    }
  }

  isActiveValueChanged() {
    this.userId = getOrCreateUid();
    // If we chose the item
    if (this.isActiveValue && this.itemUidValue && this.itemUidValue === this.userId) {
      this.addOrRemoveBtnTarget.classList.add('active');
    } 
    // If the item was chosen by someone else
    else if (this.isActiveValue && this.itemUidValue && this.itemUidValue !== this.userId) {
      this.itemTarget.classList.add('text-disabled');
      this.addOrRemoveBtnTarget.classList.add('active-disabled');
      this.addOrRemoveBtnTarget.disabled = true;
    } 
    // If no one has chosen the item
    else {
      this.addOrRemoveBtnTarget.classList.remove('active');
    }
  }

  // Makes the item selected
  _activate() {
    this.addOrRemoveBtnTarget.disabled = true;
    this._sendClickedItemData('selected');
  }

  // Makes the item deselected
  _deactivate() {
    this.addOrRemoveBtnTarget.disabled = true;
    this._sendClickedItemData('unselected');
    // Clear the amount due. When the controller is reconnected, it will be calculated again
    this.dispatch('clearAmountToPay')
  }

  async _sendClickedItemData(paramName) {
    const params = new URLSearchParams();
    params.append(paramName, this.itemIdValue);
    params.append('uid', this.userId);

    const url = `${window.location.href}/select_item?${params.toString()}`;

    try {
      await fetch(url);
    } catch (error) {
      // TODO: When we process errors we will need to fix them
      console.error('Error sending request:', error);
    } finally {
      this.addOrRemoveBtnTarget.disabled = false;
    }
  }
}
