import moment from 'moment';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'input', // inputs with values for selection in the selector
    'button' // input for collapsing and expanding select
  ];

  submitForm(form) {
    try {
      if (form.requestSubmit) {
        form.requestSubmit();
      } else {
        form.submit();
      }
    } catch (error) {
      // TODO: Error handling will be required
      console.error('Error submitting form:', error);
    }
  }

  submit() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const form = this.element;

      const selectedValue = this.inputTargets.find(input => input.checked)
        ?.nextElementSibling.innerText;

      if (selectedValue === 'Custom day' || selectedValue === 'Custom period') {
        // Break the function to show a calendar instead of a request to the server
        return;
      }

      this.submitForm(form);
    }, 200);
  }

  submitWithSelectedDate() {
    const periodValue = this.buttonTarget.value;
    const selectedValue = this.inputTargets.find(input => input.checked)
      ?.nextElementSibling.innerText;
    const form = this.element;

    if (periodValue.length > 3) {
      const url = new URL(form.action);

      const params = new URLSearchParams(url.search);

      // Append period data
      if (selectedValue === 'Custom period') {
        const dateStrings = periodValue
          .split('-')
          .map(date => moment(date, 'DD.MM.YYYY').format('DD-MM-YYYY'));
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];

        params.append('filters[start_date]', startDate);
        params.append('filters[end_date]', endDate);
      } 
      // Append day data
      else {
        params.append('filters[custom_day]', periodValue);
      }

      url.search = params.toString();

      form.action = url.toString();

      this.submitForm(form);
    }
  }
}
