import PageLoadingController from '../page_loading_controller';

export default class extends PageLoadingController {
  static targets = [
    ...PageLoadingController.targets,
    'amountToPay', // final amount
    'yourBill', // the user's current bill is displayed in the tip window
    'tipAmount',
    'tipsModal', // modal window with tips
    'overbillingModal', // modal window with overbilling
    'radio',  // radio buttons with tips
    'tipTypeInput',
    'wantToPayAmount', // the amount the user wants to pay with checkout fee in overbilling modal
    'overbillingToTipsAmount', // Amount of tip when converting over bill amount to tip in overbilling modal
    'overbillingLeftToPay', // remaining amount to pay in overbilling modal
    'customTipAmountInput', // input with an arbitrary tip amount
    'form',
    'payments',
    'leftToPay',
    'turboLeftToPay', // the remaining amount to be paid, which is updated via Turbo
    'paymentBtn',
    'minimumErrorMessage',
    'checkoutFee',
    'tipsVariant1Tooltip', // tooltip variant for radio buttons with tips
    'tipsVariant2Tooltip', // tooltip variant for radio buttons with tips
    'tipsVariant3Tooltip', // tooltip variant for radio buttons with tips
    'noTipsVariantTooltip' // tooltip variant for radio buttons with tips
  ];

  static values = {
    total: Number,
    checkoutFee: Number,
    fixedCheckoutFee: Number
  };

  totalAmount = 0;

  userWantsToPay = 0;
  userLeftToPayAmount = 0;

  tipAmountTargetConnected() {
    this.tipAmountTarget.innerText = '0.00';
  }

  _getTotalValue() {
    return this.totalValue;
  }

  _formatTotalAmount(amount) {
    if (!Number.isInteger(amount)) amount = amount.toFixed(2);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // TODO: find a way to get it iso with Money#humanized_money_with_symbol rails
  _humanizedMoneyWithSymbol(amount) {
    return this._formatTotalAmount(amount);
  }

  _addInputValue(input) {
    const inputValue = parseFloat(input, 2);
    this.updatePaymentBtnState(inputValue);
    if (inputValue > 0) {
      this.totalAmount = +this.totalAmount + inputValue;
      this.tipAmountTarget.innerText = `${inputValue}`;
    } else {
      this.tipAmountTarget.innerText = '0.00';
    }
  }

  _precessCheckoutFee() {
    if (this.fixedCheckoutFeeValue > 0) {
      this.totalAmount = (+this.totalAmount + +this.fixedCheckoutFeeValue).toFixed(2);
    } else if (this.checkoutFeeValue > 0) {
      const checkoutFeeAmount = (this.totalAmount * (this.checkoutFeeValue / 100)).toFixed(2);

      this.checkoutFeeTargets.forEach(checkoutFeeTarget => checkoutFeeTarget.innerHTML = checkoutFeeAmount);

      this.totalAmount += +checkoutFeeAmount;
    }
  }

  _processTips() {
    let checkedValue, value;
    this.hideAllTipsTooltips();

    this.radioTargets.forEach((element) => {
      const label = document.getElementById('label-' + element.id);

      this.updatePaymentBtnState();

      if (element.classList.contains('percentage-tip')) {
        value = this.totalAmount * (element.value / 100);
        label.innerHTML = this._humanizedMoneyWithSymbol(value);

        if (element.checked) {
          checkedValue = +value;
          this.showTipTooltip(element.id);
        }
      } else {
        value = (element.value / (this.totalAmount || 1)) * 100;
        label.innerHTML = this._formatTotalAmount(value) + '%';

        if (element.checked) {
          checkedValue = +element.value;
          this.showTipTooltip(element.id);
        }
      }
    });

    if (checkedValue) {
      this.totalAmount = +this.totalAmount + checkedValue;
      this.tipAmountTarget.innerText = ` ${checkedValue.toFixed(2)}`;
    } else {
      this._addInputValue(this.customTipAmountInputTarget.value);
    }
  }

  _findSelectedTip() {
    return this.radioTargets.find(element => element.checked);
  }

  _fillTipTypeInput() {
    const tipRadio = this._findSelectedTip();
    if (!tipRadio) return;

    const tipType = tipRadio.classList.contains('percentage-tip') ? 'percentage' : 'absolute';
    this.tipTypeInputTarget.value = tipType;
  }

  submit(event) {
    event.preventDefault();
    this._fillTipTypeInput();
    this.formTarget.submit();
  }

  hideTipsModal(event) {
    event.preventDefault();

    this.tipsModalTarget.classList.remove('fixed');
    this.tipsModalTarget.classList.add('hidden');
  }

  showTipsModal(event) {
    event.preventDefault();
    document.querySelector("input[name='payment_intent[allow_overbilling_to_tip]']")?.remove();

    this.tipsModalTarget.classList.remove('hidden');
    this.tipsModalTarget.classList.add('fixed');
  }

  hideOverbillingModal() {
    this.overbillingModalTarget.classList.add('hidden');
    this.dispatch('payWhatsLeft');
  }

  payWhatsLeft(event) {
    event.preventDefault();
    if (this.hasOverbillingModalTarget) {
      this.hideOverbillingModal();
    }

    if (
      this.hasTurboLeftToPayTarget &&
      +this.amountToPayTarget.innerText > +this.turboLeftToPayTarget.innerText
    ) {
      this.amountToPayTarget.innerText = +this.turboLeftToPayTarget.innerText;
    }

    this.showTipsModal(event);
  }

  showOverbillingModal(event) {
    event.preventDefault();

    if (this.hasTurboLeftToPayTarget) {
      this.userLeftToPayAmount = +this.turboLeftToPayTarget.innerText;
    }

    if (this.userWantsToPay <= this.userLeftToPayAmount) {
      this.showTipsModal(event);
      return;
    }

    this._createOverbillingToTipsFormParam(true);

    if (this.hasLeftToPayTarget) {
      this.userLeftToPayAmount = this.leftToPayTarget;
    }

    this.wantToPayAmountTarget.innerText = (+this.userWantsToPay).toFixed(2);
    this.overbillingToTipsAmountTargets.forEach((overbillingToTipsAmountTarget) => {
      overbillingToTipsAmountTarget.innerText = (+this.userWantsToPay - +this.userLeftToPayAmount).toFixed(2);
    });

    this.overbillingLeftToPayTarget.innerText = (this.userLeftToPayAmount).toFixed(2);

    this.overbillingModalTarget.classList.remove('hidden');
  }

  _createOverbillingToTipsFormParam(isAllowOverbillingToTips) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'payment_intent[allow_overbilling_to_tip]';
    hiddenInput.value = isAllowOverbillingToTips;

    this.formTarget.appendChild(hiddenInput);
  }

  updatePaymentBtnState(inputValue) {
    if (this.radioTargets.some(el => el.checked === true) || inputValue > 0) {
      this.paymentBtnTarget.removeAttribute('disabled');
    } else {
      this.paymentBtnTarget.setAttribute('disabled', true);
    }
  }

  hideAllTipsTooltips() {
    const tipsTargetsNames = [
        'tipsVariant1TooltipTarget',
        'tipsVariant2TooltipTarget',
        'tipsVariant3TooltipTarget',
        'noTipsVariantTooltipTarget'
    ];

    tipsTargetsNames.forEach(tipTargetName => this[tipTargetName].classList.add('hidden'));
  }

  showTipTooltip(tipId) {
    const tooltipDictionary = {
      'tip-1': 'tipsVariant1TooltipTarget',
      'tip-2': 'tipsVariant2TooltipTarget',
      'tip-3': 'tipsVariant3TooltipTarget',
      'no-tip': 'noTipsVariantTooltipTarget'
    };

    const targetName = tooltipDictionary[tipId];

    this[targetName].classList.remove('hidden');
  }
}
