import datepicker from 'js-datepicker';
import moment from 'moment';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'calendar', // an element that wraps the entire calendar
    'updateBtn', // button to update data by date
    'datepicker', // next to this element there will be a datepicker calendar
    'startRangeInput', // input for display and enter start range date for a custom period
    'endRangeInput', // input for display and enter start range date for a custom period
    'inputsWrapper' // wrapper for inputs for changing and displaying date ranges
  ];

  listeners = [];
  picker = null;
  startRangePicker = null;
  endRangePicker = null;

  selectedDate = null;
  selectedStartRange = null;
  selectedEndRange = null;

  connect() {
    const events = ['keydown', 'blur'];

    const targets = [
      {
        target: 'startRangeInputTarget',
        picker1: 'startRangePicker',
        picker2: 'endRangePicker'
      },
      {
        target: 'endRangeInputTarget',
        picker1: 'endRangePicker',
        picker2: 'startRangePicker'
      }
    ];

    // Adding event handlers for entering to inputs a custom range date
    targets.forEach(({ target, picker1, picker2 }) => {
      events.forEach((event) => {
        const eventHandler = e => this._handleRangeInputEvent(e, target, picker1, picker2);
        this[target].addEventListener(event, eventHandler);

        // Save the references for cleanup
        this.listeners.push({ target, event, eventHandler });
      });
    });

    // Restoring the range and selected date from local storage to show it in the select
    this.selectedStartRange = localStorage.getItem('selectedStartRange') || null;
    this.selectedEndRange = localStorage.getItem('selectedEndRange') || null;
    this.selectedDate = localStorage.getItem('selectedDate') || null;
    this._isRangeSelected(this.selectedStartRange, this.selectedEndRange);
    this._isDateSelected(this.selectedDate);

    // Clear the range and selected date and remove it from local storage
    // so that it can be displayed correctly in the selector.
    localStorage.removeItem('selectedStartRange');
    localStorage.removeItem('selectedEndRange');
    localStorage.removeItem('selectedDate');
    this.selectedStartRange = null;
    this.selectedEndRange = null;
    this.selectedDate = null;
  }

  disconnect() {
    this._clearExistingPickers(['startRangePicker', 'endRangePicker', 'picker']);

    // Save range to local storage
    if (this.selectedStartRange && this.selectedEndRange) {
      localStorage.setItem('selectedStartRange', this.selectedStartRange);
      localStorage.setItem('selectedEndRange', this.selectedEndRange);
    }
    // Save date to local storage
    if (this.selectedDate) {
      localStorage.setItem('selectedDate', this.selectedDate);
    }
    // Clear listeners
    this.listeners.forEach(({ target, event, eventHandler }) => {
      this[target].removeEventListener(event, eventHandler);
    });
  }

  show({ detail: { selectedValue } }) {
    // Clearing all existing calendars before displaying a new one
    this._clearExistingPickers(['startRangePicker', 'endRangePicker', 'picker']);

    // Show a calendars to select a date range
    if (selectedValue === 'Custom period') {
      this.inputsWrapperTarget.classList.remove('hidden');

      this.startRangePicker = datepicker(
        this.startRangeInputTarget,
        this._createDateRangePickerConfig(
          'selectedStartRange',
          'startRangePicker',
          'endRangePicker'
        )
      );
      
      this.endRangePicker = datepicker(
        this.endRangeInputTarget,
        this._createDateRangePickerConfig(
          'selectedEndRange',
          'endRangePicker',
          'startRangePicker'
        )
      );

      // Hide the end range calendar to display the start range calendar on first rendering.
      this.endRangePicker.calendarContainer.classList.add('hidden');

      const focusHandler = this._handleRangeInputFocus.bind(this);
      
      // When focusing on the input of the starting range, we show a calendar for selecting the starting range
      this.startRangeInputTarget.addEventListener('focus', focusHandler);

      // When focusing on the input of the end range, we show a calendar for selecting the end range
      this.endRangeInputTarget.addEventListener('focus', focusHandler);

      // Save the references for cleanup
      this.listeners.push({ target: 'startRangeInputTarget', event: 'focus', eventHandler: focusHandler });
      this.listeners.push({ target: 'endRangeInputTarget', event: 'focus', eventHandler: focusHandler });
    }

    // Show a calendar for selecting one date
    else if (selectedValue === 'Custom day') {
      this.inputsWrapperTarget.classList.add('hidden');

      this.picker = datepicker(this.datepickerTarget, {
        startDay: 1,
        showAllDates: true,
        onSelect: (instance, date) => {
          // Set the selected date, if it is not there, overwrite the previous date value
          if (date) {
            this.selectedDate = moment(date);
          } else {
            this.selectedDate = null;
          }

          // Extract date from select and unlock button
          if (this.selectedDate) {
            const monthNumber = this.selectedDate.month() + 1;
            const day = this.selectedDate.date();
            const year = this.selectedDate.year();
            const fullMonth = this.selectedDate.format('MMMM');

            this.updateBtnTarget.removeAttribute('disabled');

            // Create an event and pass the received data there
            this.dispatch('selectedDate', {
              detail: {
                monthNumber,
                year,
                fullMonth,
                day
              }
            });
          }
          // Reset select and lock button
          else {
            this.updateBtnTarget.setAttribute('disabled', true);
            this.dispatch('resetSelect');
            this.selectedDate = null;
          }
        }
      });
    }

    // Display a calendar to select a date
    this.calendarTarget.classList.remove('hidden');
  }

  hide() {
    this.calendarTarget.classList.add('hidden');
    this.dispatch('resetSelect');
    this.selectedDate = null;
  }

  update() {
    if (
      this.selectedDate ||
      (this.selectedStartRange && this.selectedEndRange)
    ) {
      this.dispatch('updateDataWithDate');
    }
  }

  _handleRangeInputFocus(e) {
    // Show startRangePicker
    if (e.target === this.startRangeInputTarget) {
      this.startRangePicker.calendarContainer.classList.remove('hidden');
      this.endRangePicker.calendarContainer.classList.add('hidden');
    } 
    // Show endRangePicker
    else if (e.target === this.endRangeInputTarget) {
      this.startRangePicker.calendarContainer.classList.add('hidden');
      this.endRangePicker.calendarContainer.classList.remove('hidden');
    }
  }

  _isRangeSelected(selectedStartRange, selectedEndRange) {
    // If range is selected
    if (selectedStartRange && selectedEndRange) {
      this.updateBtnTarget.removeAttribute('disabled');
      // Create an event and pass the received data there
      this.dispatch('selectedRange', {
        detail: {
          selectedStartRange,
          selectedEndRange
        }
      });
    }
  }

  _isDateSelected(selectedDate) {
    if (selectedDate) {
      this.updateBtnTarget.removeAttribute('disabled');
      const date = moment(selectedDate);

      const monthNumber = date.month() + 1;
      const fullMonth = date.format('MMMM');
      const year = date.year();
      const day = date.date(); 

      // Create an event and pass the received data there
      this.dispatch('selectedDate', {
        detail: {
          monthNumber,
          fullMonth,
          year,
          day
        }
      });
    }
  }

  _clearExistingPickers(pickersArray) {
    pickersArray.forEach((picker) => {
      if (this[picker]) {
        this[picker].remove();
        this[picker] = null;
      }
    });
    this.updateBtnTarget.setAttribute('disabled', true);
    this.startRangeInputTarget.value = '';
    this.endRangeInputTarget.value = '';
  }

  _handleRangeInputEvent(event, inputTarget, picker, oppositePicker) {
    if (
      (event.type === 'keydown' && event.key === 'Enter') ||
        event.type === 'blur'
      ) {
      const dateStr = this[inputTarget].value;
      const dateObj = moment(dateStr, 'DD.MM.YYYY');
  
      // Insert the selected date into the calendar, if there is a period, insert it into the select,
      // show the opposite calendar
      if (dateObj.isValid()) {
        this._setRangePickerDate(picker, dateObj);
  
        if (picker === this.startRangePicker) {
          this.selectedStartRange = dateStr;
        } else {
          this.selectedEndRange = dateStr;
        }
  
        this._isRangeSelected(this.selectedStartRange, this.selectedEndRange);
  
        this[oppositePicker].calendarContainer.classList.remove('hidden');
        this[picker].calendarContainer.classList.add('hidden');
      }
    }
  }

  _createDateRangePickerConfig = (selectedDate, thisPicker, otherPicker) => {
    return {
      id: 1,
      startDay: 1,
      showAllDates: true,
      formatter: (input, date, instance) => {
        input.value = moment(date, 'ddd MMM DD YYYY').format('DD.MM.YYYY');
      },
      onSelect: (instance, date) => {
        // Set range, if it is not there, overwrite the previous date value
        if (date) {
          this[selectedDate] = moment(date, 'ddd MMM DD YYYY').format('DD.MM.YYYY');

          // After selecting the date, hide current calendar and show opposite calendar
          this[otherPicker].calendarContainer.classList.remove('hidden');
          this[thisPicker].calendarContainer.classList.add('hidden');
        } 
        // Reset selected range and lock button
        else {
          this.updateBtnTarget.setAttribute('disabled', true);
          this.dispatch('resetSelect');
          this[selectedDate] = null;
        }
  
        // The selected range, if any, is displayed in the selector.
        this._isRangeSelected(this.selectedStartRange, this.selectedEndRange);
      }
    };
  };

  _setRangePickerDate(picker, dateObj) {
    const formattedDate = dateObj.toDate();
    this[picker].setDate(formattedDate);
  }

  writeValueToStartRangeInput(e) {
    const inputValue = e.target.value;

    // Leave only numbers and dots
    const filteredValue = inputValue.replace(/[^0-9.]/g, '');
    this.startRangeInputTarget.value = filteredValue;
  }

  writeValueToEndRangeInput(e) {
    const inputValue = e.target.value;

    // Leave only numbers and dots
    const filteredValue = inputValue.replace(/[^0-9.]/g, '');
    this.endRangeInputTarget.value = filteredValue;
  }
}
